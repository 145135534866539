import NotFound from "pages/NotFound";

const routers = [

  {
    path: "/",
    redirect: "/auth",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        path: "/forgot-password",
        componentPath: "pages/Auth/ForgotPassword",
        name: "ForgotPassword",
        auth: false,
        exact: true,
      },
      {
        component: "Verification",
        path: "/verification",
        componentPath: "pages/Auth/Verification",
        name: "Verification",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPassword",
        path: "/reset-password",
        componentPath: "pages/Auth/ResetPassword",
        name: "ResetPassword",
        auth: false,
        exact: true,
      },

    ],
    
    
  },


  {
    component: "MainLayout",
    path: "/admin",
    auth: false,
    name: "Dashboard",
    exact: false,
    redirect: "/admin/dashboard",
    childrens: [
      // DASHBOARD
      {
        component: "UserOnBoardComp",
        path: "/sheets",
        componentPath: "pages/UserOnBoard/index",
        name: "UserOnBoardComp",
        auth: false,
        exact: true,
      },
      {
        component: "CreateUser",
        path: "/create-sheet",
        componentPath: "pages/UserOnBoard/CreateUser",
        name: "CreateUser",
        auth: false,
        exact: true,
      },
      {
        component: "TelegramPage",
        path: "/telegram",
        componentPath: "pages/TelegramPage/index",
        name: "TelegramPage",
        auth: false,
        exact: true,
      },
      {
        component: "CreateTelegramPage",
        path: "/create-telegram",
        componentPath: "pages/TelegramPage/CreateTelegram",
        name: "CreateTelegramPage",
        auth: false,
        exact: true,
      },
      {
        component: "EmailSheetPage",
        path: "/email",
        componentPath: "pages/EmailAutomationsPage/index",
        name: "EmailSheetPage",
        auth: false,
        exact: true,
      },
      {
        component: "CreateEmailSheet",
        path: "/create-email",
        componentPath: "pages/EmailAutomationsPage/CreateEmailSheet",
        name: "CreateEmailSheet",
        auth: false,
        exact: true,
      },
      {
        component: "ProjectUpdatesPage",
        path: "/project-updates",
        componentPath: "pages/ProjectUpdatesPage/index",
        name: "ProjectUpdatesPage",
        auth: false,
        exact: true,
      },
      {
        component: "CreateProjectUpdatesPage",
        path: "/create-project-updates",
        componentPath: "pages/ProjectUpdatesPage/CreateProjectUpdates",
        name: "CreateProjectUpdatesPage",
        auth: false,
        exact: true,
      },
      {
        component: "SchoolOnBoardComp",
        path: "/members",
        componentPath: "pages/SchoolOnBoard/index",
        name: "SchoolOnBoardComp",
        auth: false,
        exact: true,
      },
      {
        component: "CreateSchoolUser",
        path: "/create-member",
        componentPath: "pages/SchoolOnBoard/CreateSchoolUser",
        name: "CreateSchoolUser",
        auth: false,
        exact: true,
      },
      {
        component: "ViewSchoolUser",
        path: "/view-school-user",
        componentPath: "pages/SchoolOnBoard/ViewSchoolUser",
        name: "ViewSchoolUser",
        auth: false,
        exact: true,
      },
      {
        component: "Profile",
        path: "/profile",
        componentPath: "pages/ProfilePage/index",
        name: "Profile",
        auth: false,
        exact: true,
      },
      {
        component: "EditProfile",
        path: "/edit-profile",
        componentPath: "pages/ProfilePage/EditProfile",
        name: "EditProfile",
        auth: false,
        exact: true,
      },
      {
        component: "ChangePassword",
        path: "/change-password",
        componentPath: "pages/ProfilePage/ChangePassword",
        name: "ChangePassword",
        auth: false,
        exact: true,
      },
      {
        component: "Notification",
        path: "/notification",
        componentPath: "pages/NotificationPage/index",
        name: "Notification",
        auth: false,
        exact: true,
      },
    ],

  },
]
export default routers

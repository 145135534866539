import { Avatar } from "@material-ui/core";
import React, { useEffect, forwardRef, useState } from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { logout } from "service/utilities";
import logo from "assets/images/elmo_title.png";
import notificationBadge from "assets/images/notification_badge.png";
import user from "assets/images/menOne.jpg";
import bell from "assets/icons/bell.png";
import "component/common/Header/header.scss";
import search from "assets/icons/search.svg";
import { Link } from "react-router-dom";
import profile_placeholder from "../../../assets/images/profile_placeholder.png";
import { request } from "service";
import endponts from "service/endponts";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFEFE",
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
}));
const Header = () => {
  const { header } = useStyles();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    console.log(userData,"userDatauserDatauserData");
    setUserName(userData?.userName);
  }, []);


  


  const displayDesktop = () => {
    return (
      <Toolbar style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)" }}>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-md-4 d-flex align-items-center">
              <div>
                <p className="title_auto">Doodleblue Automations</p>
              </div>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="d-flex justify-content-between align-items-center">
                <div className="userName">Logged In as : {userName}</div>
              </div>
            </div>
          </div>
        </div>
      </Toolbar >
    );
  };

  return (
    <div className="header">
      <header>
        <AppBar style={{ boxShadow: "none", backgroundColor: "white" }}>
          {displayDesktop()}
        </AppBar>
      </header>
    </div>
  );
};

export default Header;

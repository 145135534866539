const Endpoints = {
   
    //Auth
    login:'/signIn',
    signUp:'/signUp',
    forgotPassword:'/forgotPassword',
    verifyOTP:'/verifyOTP',
    resetPassword:'/changePassword',

    getSheets:'/getAllSheets',
    getAllUsers:'/getAllUsers',
    deleteUserById:'/deleteUserById',
    getUserById:'/getUserById',

    getAllTelegramGroup:'/getAllTelegramGroup',
    addTelegramGroup:'/addTelegramGroup',
    deleteTelegramGroup:'/deleteTelegramGroup',
    getAllTabsBySheetId:'/getAllTabsBySheetId',
    saveSheet:'/saveSheet',
    updateSheet:'/updateSheet',
    deleteSheetById:'/deleteSheetById',
    manualTriggerSheet:'/manualTriggerSheet',


    getAllEmailSheets:'/getAllEmailSheets',
    updateEmailSheet:'/updateEmailSheet',
    createEmailSheet:'/createEmailSheet',
    deleteEmailSheet:'/deleteEmailSheet',
    disableSheet:'/disableSheet',
    manualTriggerEmailSheet:'/manualTriggerEmailSheet',

    projectList:'/projectList',
    createProjectUpdate:'/createProjectUpdate',
    getAllProjectUpdate:'/getAllProjectUpdate',
    getProjectUpdateById:'/getProjectUpdateById',
    deleteProjectUpdateById:'/deleteProjectUpdateById',
    updateActiveStatusOfProjectUpdateById:'/updateActiveStatusOfProjectUpdateById',
    updateProjectUpdate:'/updateProjectUpdate',

}


const APIMethods = {
    POST: 'POST',
    GET: 'GET',
    PUT:'PUT',
    DELETE:'DELETE',
    PATCH:'PATCH',
}

export default {
    Endpoints,
    APIMethods
}


